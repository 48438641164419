var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    attrs: {
      "dismissible": !_vm.noCloseBtn,
      "color": _vm.messageType,
      "border": "left",
      "icon": _vm.$options.iconMap[_vm.messageType],
      "outlined": _vm.outlined
    }
  }, [_c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.secondaryMessage) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }